import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import LoginView from "@/views/LoginView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: LoginView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  const token: string | null = localStorage.getItem("token");
  if (!token && to.path !== '/') {
    next('/');
  } else {
    next();
  }
})

export default router
