<template>
  <div class="login">
    <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="60px"
        class="demo-ruleForm"
        size="20"
        status-icon
    >
      <h2>小黑的生活拾遗</h2>
      <el-form-item label="账号" prop="username">
        <el-input v-model="ruleForm.username"/>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="ruleForm.password"/>
      </el-form-item>
      <el-form-item>
        <el-button class="btn" type="primary" @click="submitForm(ruleFormRef)">
          登录
        </el-button>
        <el-button class="btn" @click="resetForm(ruleFormRef)">
          重置
        </el-button>
      </el-form-item>
    </el-form>
    <a href="http://beian.miit.gov.cn" style="color:#fff;">粤ICP备2023134782号</a>
  </div>
</template>
<script lang="ts">
import {defineComponent, reactive, ref, toRefs} from 'vue';
import type {FormInstance} from 'element-plus';
import {login} from "@/request/api";
import {useRouter} from "vue-router";

interface ILoginForm {
  username: string,
  password: string
}

class LoginData {
  ruleForm: ILoginForm = {
    username: "",
    password: ""
  }
}

export default defineComponent({
  setup() {
    const $router = useRouter();
    const data = reactive(new LoginData());
    const ruleFormRef = ref<FormInstance>()
    const submitForm = (formEl: FormInstance | undefined) => {
      console.log(formEl)
      if (!formEl) return
      formEl.validate((valid, fields) => {
        if (valid) {
          login(data.ruleForm).then((res) => {
           alert("密码错误");

          }, (err) => {
            console.log(err);
          });
        } else {
          console.log('error submit!', fields)
        }
      })
    }
    const resetForm = (formEl: FormInstance | undefined) => {
      if (!formEl) return
      formEl.resetFields()
    }
    const rules = {
      username: [
        {
          required: true,
          message: '账号不能为空',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 10,
          message: '账号长度应在3-10之间',
          trigger: 'blur'
        },
      ],
      password: [
        {
          required: true,
          message: '密码不能为空',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 10,
          message: '密码长度应在3-10之间',
          trigger: 'blur'
        },
      ],
    }
    return {
      ...toRefs(data),
      ruleFormRef,
      resetForm,
      rules,
      submitForm
    }
  }
});
</script>
<style lang="scss">
.login {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 1px;
  background-image: url("../assets/bg.jpg");

  .demo-ruleForm {
    background-color: #f9f9f9;
    padding: 25px;
    width: 500px;
    margin: 300px auto;
    border-radius: 20px;

    .btn {
      width: 48%;
    }
  }
}

h2 {
  padding: 10px;
}
</style>