<script lang="ts">

</script>

<template>
  <router-view/>
</template>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}

body, #app, html {
  min-width: 100%;
  height: 100%;
}
</style>
